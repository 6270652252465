import { ReviewFormModalProps } from "@hellodarwin/core/lib/components/reviews/review/review-form-modal";
import { HubspotContainerEntity } from "./containers-entities";
import { GrantEntity } from "./directory-entities";
import { WebsiteProvider } from "./providers-entities";

export interface SubmitProjectModalProps {
  campaignid: string;
  city?: string;
  service?: string;
  categories?: string[];
  provider: WebsiteProvider;
}

export type ReviewModalProps = Omit<
  ReviewFormModalProps,
  "open" | "handleClose"
>;
export interface QuitModalProps {
  handleQuit: () => void;
}

export interface GrantInquiryModalProps {
  activeGrant?: GrantEntity;
}
export interface HubspotFormModalProps {
  hubspotFormId?: string;
  containerTag: string;
  additionalFields?: HubspotContainerEntity["additionalFields"];
}

export interface ModalData {
  type: string;
  visibile: boolean;
  props?:
    | QuitModalProps
    | GrantInquiryModalProps
    | SubmitProjectModalProps
    | HubspotFormModalProps
    | ReviewModalProps;
}

export const InitialModalData: ModalData = {
  type: "",
  visibile: false,
  props: {},
};

