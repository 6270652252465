import Button from "@hellodarwin/core/lib/components/common/button";
import { Language } from "@hellodarwin/core/lib/features/entities/translations_ns";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import LanguageIcon from "@hellodarwin/icons/dist/icons/Language";
import { useMemo } from "react";
import { useRoot } from "../../../../context/RootProvider";
import theme from "../../../../utils/styles/theme";

export const SUPPORTED_LANGUAGES: Language[] = ["fr", "en"];

interface LanguageSwitcherProps {
  isLong?: boolean;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ isLong }) => {
  const { selectedLocale, handleLanguageSwitcher } = useRoot();
  const { t } = useTranslations();

  const otherLang = useMemo(() => {
    return SUPPORTED_LANGUAGES.find((lang) => selectedLocale !== lang);
  }, [selectedLocale]);

  const clickHandler = () => {
    if (otherLang) {
      handleLanguageSwitcher(otherLang);
    }
  };

  return (
    <>
      <Button
        defaultStyle={theme.colors.grey_5}
        onClick={clickHandler}
        key={otherLang}
        data-locale={otherLang}
        size={!isLong ? "square" : "small"}
        style={{
          textTransform: !isLong ? "uppercase" : undefined,
        }}
        headingIcon={isLong ? <LanguageIcon size={24} /> : undefined}
      >
        {isLong ? t(`language|${otherLang}`) : otherLang}
      </Button>
    </>
  );
};

export default LanguageSwitcher;

