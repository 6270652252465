import { Slice } from "gatsby";
import React, { PropsWithChildren, useContext } from "react";
import {
  InitialModalData,
  ModalData,
} from "../features/entities/modals-entities";

export interface ModalContextType {
  modal: ModalData;
  openModal: ({ type, visibile, props }: ModalData) => void;
  closeModal: () => void;
}

const ModalContext = React.createContext<ModalContextType>({
  modal: InitialModalData,
  openModal: ({ type, visibile, props }) => {},
  closeModal() {},
});

type ModalProviderProps = {} & PropsWithChildren;

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModal] = React.useState<ModalData>(InitialModalData);

  const closeModal = () => {
    setModal(InitialModalData);
  };

  const openModal = (data: ModalData) => {
    setModal(data);
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        closeModal,
      }}
    >
      {children}
      <Slice allowEmpty alias="modal" />
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const { ...modalProps } = useContext(ModalContext);
  return { ...modalProps };
};

export default useModal;

