import { createGlobalStyle } from "@hellodarwin/core/lib/plugins/styled";
import { MenuDrawer } from "../../slices/Header/components/Menu/styles";
import { HeaderStyled } from "../../slices/Header/styles";
import reset from "./reset";
import { defaultTheme } from "./theme";

const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-padding-top: ${({ theme }) => theme.appearance.headerHeight}px !important;
    overflow: initial !important;
  }
  body {
    font-family: Inter;
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.beige_3};
    /* scroll-behavior: smooth; */
    width: 100vw;
    overflow-y:visible !important;
    overflow-x:hidden !important;
    &.hs-web-interactives-top-banner-open {
      scroll-margin-top:calc(${({ theme }) => theme.appearance.bannerHeight}px + ${({ theme }) => theme.appearance.headerHeight}px) !important;

      ${HeaderStyled} {
        top: ${({ theme }) => theme.appearance.bannerHeight}px;
      }
      ${MenuDrawer} {
          margin-top:calc(${({ theme }) => theme.appearance.bannerHeight}px + ${({ theme }) => theme.appearance.headerHeight}px);
      }
    }

    main {
      position:relative;
      width: 100vw;
      overflow-x:hidden !important;
      overflow-y:visible !important;
      &.is-email {
        height: 100vh;
        background-color:#f7f4fa;
      }
     
    }
  }

  .fade-in {
    animation: fadeIn 200ms ease-in;
  }

  
  &::selection {
    color: white;
    background-color: ${({ theme }) => theme.colors.purple_1};
  }

   /* width */
   &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.purple_5};
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.purple_1};
    transition: background 400ms ease;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.primary};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  
  p + ul {
    margin-top:16px;
  }
  /* Antd */
 

  
  .ant-modal .ant-modal-content {
    padding: 0;
  }
  
  .ant-typography a {
    color: $hd-purple-2;
    text-decoration: underline;
    &:hover {
      color: $hd-purple-1;
      text-decoration: none;
    }
    p {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  ${defaultTheme};
`;

export default GlobalStyle;

