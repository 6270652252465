import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { Slice } from "gatsby";
import React, { PropsWithChildren, useEffect } from "react";
import { PageContext } from "../../features/entities/pages-entities";

export interface DefaultLayoutProps {
  element: PropsWithChildren["children"];
  props: {
    pageContext: PageContext;
    location: Location;
    data: Record<string, unknown>;
  };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ element, props }) => {
  const { prepareTranslations } = useTranslations();

  const handleTranslations = (data: any) => {
    if (!data.allTranslations) return;

    prepareTranslations((data.allTranslations as any)?.nodes);
  };

  useEffect(() => {
    handleTranslations(props.data);
  }, [props.data]);

  return (
    <div style={{ position: "relative" }}>
      <Slice alias="header" allowEmpty />

      <main
        id="main"
        className={
          props.pageContext.designType === "Email"
            ? "is-email"
            : props.pageContext.designType === "Simple"
              ? "is-simple"
              : ""
        }
      >
        {element}
      </main>

      <Slice alias="footer" allowEmpty />
    </div>
  );
};

export default DefaultLayout;

