import { navigate } from "gatsby";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-use";
import isBrowser from "../utils/isBrowser";

function* emptyIterableIterator(): IterableIterator<[string, string]> {}

export interface PaginationContextType {
  goToPrevPage: () => void;
  goToNextPage: () => void;
  addParam: (key: string, value: string) => void;
  goToPage: (page: number) => void;
  params: IterableIterator<[string, string]>;
  getParam: (key: string) => string;
  clearAllParams: (activePage: number) => void;
  activePage: number;
}

const PAGE_KEY = "step";
export const PaginationContext = createContext<PaginationContextType>({
  goToPrevPage() {},
  goToNextPage() {},
  goToPage(page) {},
  addParam(key, value) {},
  params: emptyIterableIterator(),
  getParam(key) {
    return key;
  },
  clearAllParams(activePage) {},
  activePage: 0,
});

export const PaginationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const [activePage, setActivePage] = useState(0);

  const getParam = (key: string) => {
    return params.get(key) || "";
  };

  const data = useMemo(() => {
    return params.entries();
  }, [pathname, params]);
  const clearAllParams = (activePage: number) => {
    const params = new URLSearchParams();
    params.set(PAGE_KEY, `${activePage}`);
    const newUrl = `?${params.toString()}`;

    if (isBrowser) {
      window.history.replaceState(null, "", newUrl);
      navigate(newUrl);
    }
  };

  useEffect(() => {
    const step = parseInt(getParam(PAGE_KEY));

    if (!!step && step !== activePage) {
      setActivePage(step);
    } else {
      setActivePage(0);
    }
  }, []);

  useEffect(() => {
    const step = parseInt(getParam(PAGE_KEY));

    if (activePage !== step && activePage !== 0) {
      params.set(PAGE_KEY, `${activePage}`);
      navigate(`?${params.toString()}`, { replace: true });
    }
  }, [activePage]);

  useEffect(() => {
    const param = getParam("step");
    const value = parseInt(param);
    if (!!param && value !== activePage) {
      setActivePage(value);
    }
  }, [pathname]);

  const addParam = (key: string, value: string) => {
    if (key === PAGE_KEY) return;
    params.set(key, value);
    navigate(`?${params.toString()}`, { replace: true });
  };

  const goToPrevPage = () => {
    setActivePage(activePage - 1);
  };
  const goToNextPage = () => {
    setActivePage(activePage + 1);
  };
  const goToPage = (page: number) => {
    setActivePage(page);
  };

  return (
    <PaginationContext.Provider
      value={{
        goToNextPage,
        goToPrevPage,
        goToPage,
        addParam,
        getParam,
        clearAllParams,
        activePage,
        params: data,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);

export default PaginationProvider;

