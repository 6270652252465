import { PageProps } from "gatsby";
import React from "react";
import { ModalProvider } from "./src/context/ModalProvider";
import PageProvider from "./src/context/PageProvider";
import { PageContext } from "./src/features/entities/pages-entities";
import DefaultLayout from "./src/layout/DefaultLayout";

const WrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  props: PageProps<Record<string, unknown>, PageContext, unknown, object>;
}) => {
  return (
    <ModalProvider>
      <PageProvider pageContext={props.pageContext} data={props.data}>
        <DefaultLayout
          element={element}
          props={{
            pageContext: props.pageContext,
            location: props.location,
            data: props.data,
          }}
        />
      </PageProvider>
    </ModalProvider>
  );
};

export default WrapPageElement;

