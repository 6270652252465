import { ThemeConfig } from "antd/es/config-provider";
import { DefaultTheme } from "./theme";

const getAntdTheme = (theme: DefaultTheme) => {
  const token: ThemeConfig["token"] = {
    colorPrimary: theme.colors.purple_1,
    colorText: theme.colors.grey_1,
    borderRadius: 24,
    colorLink: theme.colors.purple_2,
    colorLinkActive: theme.colors.purple_3,
    colorLinkHover: theme.colors.purple_1,
    colorPrimaryHover: "nil",
    fontFamily: "Inter",
  };

  const websiteComponents: ThemeConfig["components"] = {
    Button: {
      borderRadius: 24,
    },
    Steps: {
      colorPrimary: theme.colors.grey_1,
      colorSplit: theme.colors.grey_2,
      colorTextDisabled: theme.colors.purple_2,
      colorText: theme.colors.grey_2,
      colorTextDescription: theme.colors.grey_2,
      colorTextLabel: theme.colors.grey_1,
      colorPrimaryBorder: theme.colors.grey_1,
      colorFillContent: theme.colors.grey_5,
    },
    Upload: {
      colorBorder: theme.colors.primary,
      lineHeight: 1,
      colorFillAlter: theme.colors.white_1,
      colorTextPlaceholder: "#757585",
      colorBgBase: "#fafaff",
      borderRadiusLG: 4,
    },
    Tag: {
      colorFillTertiary: theme.colors.purple_5,
      defaultColor: theme.colors.purple_2,
      marginXS: 0,
      paddingXS: 7,
      lineHeight: 20,
      fontSizeSM: 12,
      fontSize: 16,
      colorSuccess: "#28CE8A",
      colorWarning: "#B4B500",
      green: "#28CE8A",
      borderRadius: 18,
    },
    Spin: { colorPrimary: theme.colors.purple_1 },
    Divider: {
      colorSplit: theme.colors.grey_5,
      lineWidth: 1,
      marginLG: 0,
    },
    Typography: {
      colorLink: theme.colors.purple_2,
      colorLinkActive: theme.colors.purple_3,
      colorLinkHover: theme.colors.purple_2,
    },
    Tooltip: {
      colorBgSpotlight: theme.colors.white_2,
      colorTextLightSolid: theme.colors.primary,
      borderRadius: 4,
      colorBorder: theme.colors.grey_2,
    },
    Collapse: {
      borderRadius: 0,
      borderRadiusLG: 0,
      colorFillAlter: theme.colors.white_2,
      colorBgContainer: theme.colors.white_2,
      colorBorder: theme.colors.grey_5,
    },
    Form: {
      fontSizeLG: 14,
      fontSize: 16,
      paddingXS: 11,
      colorTextDescription: theme.colors.grey_2,
    },
    Select: {
      lineHeight: 1.4,
      borderRadius: 4,
      borderRadiusLG: 4,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorPrimaryBorder: theme.colors.purple_3,
      controlPaddingHorizontal: 14,
      paddingXXS: 7,
      controlItemBgActive: theme.colors.grey_5,
      borderRadiusSM: 0,
      fontWeightStrong: 400,
      fontSize: 14,
      fontSizeLG: 16,
      colorTextDescription: theme.colors.grey_2,
    },
    Input: {
      lineHeight: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontSize: 14,
      fontSizeLG: 16,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorTextDescription: theme.colors.grey_2,
    },
    DatePicker: {
      lineHeight: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontSize: 14,
      fontSizeLG: 16,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorTextDescription: theme.colors.grey_2,
    },
    InputNumber: {
      lineHeight: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontSize: 14,
      fontSizeLG: 16,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorTextDescription: theme.colors.grey_2,
    },
    Radio: {
      buttonBg: theme.colors.purple_1,
      buttonCheckedBg: theme.colors.purple_1,
      buttonSolidCheckedColor: theme.colors.purple_1,
      colorBorder: theme.colors.grey_1,
      wrapperMarginInlineEnd: 0,
    },
    Dropdown: {
      borderRadiusSM: 0,
      borderRadius: 0,
      borderRadiusLG: 0,
      lineHeight: 1.6,
      colorPrimaryBorder: theme.colors.primary,
      controlPaddingHorizontal: 0,
      controlItemBgHover: theme.colors.grey_5,
      colorText: theme.colors.grey_3,
      paddingXXS: 12,
    },
    Layout: {
      headerBg: theme.colors.white_1,
      bodyBg: theme.colors.white_1,
    },
    Menu: {
      colorHighlight: theme.colors.grey_1,
      itemColor: theme.colors.grey_1,
    },

    Avatar: {
      colorTextPlaceholder: theme.colors.purple_2,
    },
  };

  const antdTheme: ThemeConfig = {
    token,
    components: {
      ...websiteComponents,
    },
  };
  return antdTheme;
};
export default getAntdTheme;

