import { Language } from "@hellodarwin/core/lib/features/entities/translations_ns";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { navigate } from "gatsby";
import React, {
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-use";
import { PageLinks } from "../features/entities/directory-entities";

type PageContext = PageLinks[];

interface Breadcrumb {
  pathname: string;
  pageName: string;
  tags?: string[];
  componentsTypes?: string[];
}
export interface RootContextType {
  changeLanguage: (locale: Language) => void;
  selectedLocale: Language;
  handleLanguageSwitcher: (newLang: Language) => void;
  redirectToPageContext: (context: PageLinks) => void;
  setLocalePageContext: React.Dispatch<SetStateAction<PageContext>>;
  hasData: boolean;
  setHasData: React.Dispatch<SetStateAction<boolean>>;
  breadcrumbs: Breadcrumb[];
  addCrumb: (newCrumb: Breadcrumb) => void;

  navOpen: boolean;
  handleNav: () => void;
}

export const RootContext = React.createContext<RootContextType>({
  changeLanguage: (locale) => {},
  selectedLocale: "en",
  handleLanguageSwitcher: (newLang) => {},
  redirectToPageContext(context) {},
  setLocalePageContext: (value) => {},
  hasData: false,
  setHasData: (hasData) => {},
  breadcrumbs: [],
  addCrumb: (newCrumb) => {},
  navOpen: false,
  handleNav: () => {},
});

export const RootProvider = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({ maxWidth: theme.breakpoints.l });
  const location = useLocation();

  const { selectedLocale, changeLanguage } = useLocale();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const [localePageContext, setLocalePageContext] = React.useState<PageContext>(
    []
  );

  const [hasData, setHasData] = React.useState(false);

  const [navOpen, setNavOpen] = React.useState(false);
  const handleNav = () => setNavOpen(!navOpen);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname, isDesktop]);

  const handleLanguageSwitcher = (newLang: Language) => {
    changeLanguage(newLang);
    const context = localePageContext?.find(
      ({ node_locale }) => node_locale === newLang
    );
    redirectToPageContext(context);
  };

  const redirectToPageContext = (context?: PageLinks) => {
    if (context) {
      if (context.fullHandle) {
        const link = context as PageLinks;
        navigate(`/${link.fullHandle}`);
      }
    }
  };

  const addCrumb = (crumb: Breadcrumb) => {
    if (breadcrumbs[breadcrumbs?.length - 1]?.pathname !== crumb.pathname) {
      setBreadcrumbs([...breadcrumbs, crumb]);
    }
  };
  return (
    <RootContext.Provider
      value={{
        changeLanguage,
        selectedLocale,
        handleLanguageSwitcher,
        redirectToPageContext,
        setLocalePageContext,
        hasData,
        setHasData,
        breadcrumbs,
        addCrumb,

        handleNav,
        navOpen,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export const useRoot = () => {
  const { ...rootVariables } = useContext(RootContext);
  return {
    ...rootVariables,
  };
};

export default RootContext;

