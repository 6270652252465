import styled from "@hellodarwin/core/lib/plugins/styled";
import { ContainerContentWidthStyling } from "../../components/Container/styles";
import theme from "../../utils/styles/theme";

export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;

  width: 100vw;
  overflow: hidden;

  background-color: ${theme.colors.white_1};
  border-bottom: 1px solid ${theme.colors.primary};
  z-index: 900;
  height: ${({ theme }) => theme.appearance.headerHeight}px;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  gap: 20px;
  ${({ theme }) => ContainerContentWidthStyling({ theme, $cap: 1600 })}
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 16px 24px;
  }
`;

